html {
    min-height: calc(100% + env(safe-area-inset-top));
    /* padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
    touch-action: pan-y, pan-x;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    background-color: black;
}
body {
    margin: 0 auto;
    font-family: 'basic-sans', sans-serif;
    color: #23286b;
    font-size: 18px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 475px !important;
    touch-action: pan-y, pan-x;
    padding-top: env(safe-area-inset-top);
}

.ms-signup-header {
    font-family: 'basic-sans', sans-serif;
}
.ms-signup-container {
    height: 100%;
    padding-top: env(safe-area-inset-top);
    max-width: 475px !important;
    width: 100% !important;
    margin: 0 auto !important;
}

.ms-login-or-signup-wrap {
    bottom: 100px !important;
    top: auto !important;
}

.real-full-height-2x {
    margin-top: calc(env(safe-area-inset-top) * -2);
    min-height: calc(100% + env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
}

.real-full-height {
    margin-top: calc(env(safe-area-inset-top) * -1);
    min-height: calc(100% + env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
}
.bottom-notch-space {
    margin-bottom: calc(env(safe-area-inset-bottom)) !important;
}
.stuff-notch-space {
    margin-top: calc(80% - env(safe-area-inset-top)) !important;
}
.notch-space {
    padding-top: env(safe-area-inset-top);
}

.notch-space-bottom {
    height: calc(60px + env(safe-area-inset-bottom)) !important;
}

.notch-space-bottom-bar-insert {
    bottom: calc(-5px + env(safe-area-inset-bottom)) !important;
}
#slaask-widget {
    /* top: calc(0px + env(safe-area-inset-top)) !important; */
    height: calc(100% - (env(safe-area-inset-bottom) * 3));
    min-height: calc(100% - (env(safe-area-inset-bottom)));
    padding-bottom: 60px;
}
#slaask-widget-header-title {
    padding-top: 20px !important;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiMobileStepper-dot {
    background-color: white !important;
    opacity: 0.25;
}

.green-dots .MuiMobileStepper-dot {
    background-color: rgba(41, 63, 23, 100) !important;
    opacity: 0.25;
}

.green-dots .MuiMobileStepper-dotActive {
    background-color: rgba(41, 63, 23, 100) !important;
    opacity: 1;
}

.MuiMobileStepper-dotActive {
    background-color: white !important;
    opacity: 1;
}

.MuiBottomNavigationAction-label {
    color: #392d2c;
    font-size: 12px !important;
    line-height: 24px;
}

.MuiBottomNavigationAction-root .Mui-selected {
    color: #392d2c;
}

#ms-warning-box {
    display: none !important;
}

#stories-component > div:first-child > div:first-child > * {
    background: rgba(255, 255, 255, 0.35) !important;
}

#stories-component > div > div:first-child {
    padding-top: 15px !important;
}

#slaask-widget {
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 475px;
    transform: translate(-50%, -50%);
    height: 94vh;
    padding-top: 0px;
}
#slaask-button-image,
#slaask-button-cross,
#slaask-button {
    display: none !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent;
    opacity: 0 !important;
}
.slaask-iframe .slaask-widget.slaask-mini .slaask-widget-header,
.slaask-widget .slaask-widget-header {
    padding-top: 10px !important;
}

.ms-style .ms-signup-header {
    box-shadow: none;
}

.quiz-data-view {
    margin-top: 12vh;
}

.quiz-data-last-view {
    margin-top: 8vh;
}
/* WebKit styles */
#progress_bar {
    display: block;
    margin-top: calc(30px - (env(safe-area-inset-top)/2));
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5px;
    padding: 0 20px;
    height: 4px;
    border-radius: 10px;
}
#progress_bar::-webkit-progress-bar {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.25);
}
#progress_bar::-webkit-progress-value {
    padding: 1px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
}
#progress_bar::-moz-progress-bar {
    border-radius: 5px;
    background-color: rgba(63, 63, 63, 0.363);
}
/* #stories-component > div:first-child > div:last-child{

justify-content: space-between;

} 
#stories-component > div:first-child > div:last-child div{
width: 35% !important;
} */
#sk_audio {
    position: absolute;
    top: 50%;
}
/* @media screen (min-width:500px){ */
/* @media screen and (orientation:landscape)  and (min-device-width: 290px) and (max-device-width: 580px) {
  body {
    height: 100vw;
   
    transform: rotate(90deg);
  }
} */
#main-lessun {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
}
.move-right {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    transform: translateX(150%);
    -webkit-transform: translateX(50%);
}
.move-left {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    transform: translateX(-150%);
    -webkit-transform: translateX(-50%);
}

.ms-style .ms-close-signup {
    position: unset !important;
}

.iOSPWA-overlay,
.iOSPWA-container {
    z-index: 3314748364 !important;
}
